var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "薪资档级",
                  prop: "salaryGrade",
                  rules: [
                    {
                      required: true,
                      validator: _vm.validateSalary,
                      trigger: "blur",
                    },
                  ],
                },
              },
              [
                _c("v-select", {
                  attrs: {
                    options: _vm.salaryGradeOps,
                    width: _vm.width,
                    disabled: !_vm.form.tenantId || _vm.isDisabled,
                  },
                  on: { change: _vm.getbasicInfo },
                  model: {
                    value: _vm.form.salaryGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "salaryGrade", $$v)
                    },
                    expression: "form.salaryGrade",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "基本工资",
                text: _vm.salaryStandardInfo.baseSalary || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "绩效工资",
                text: _vm.salaryStandardInfo.performanceSalary || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "社会保险",
                text: _vm.salaryStandardInfo.socialInsurance || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "社险补贴",
                text: _vm.salaryStandardInfo.socialInsuranceSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "公积金",
                text: _vm.salaryStandardInfo.providentFund || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "公积金补贴",
                text: _vm.salaryStandardInfo.providentFundSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "通讯补贴",
                text: _vm.salaryStandardInfo.communicationSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "交通补贴",
                text: _vm.salaryStandardInfo.transportationSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "餐贴",
                text: _vm.salaryStandardInfo.mealSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "汽油费补贴",
                text: _vm.salaryStandardInfo.petrolSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "岗位补贴",
                text: _vm.salaryStandardInfo.jobRankSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "其他补贴",
                text: _vm.salaryStandardInfo.otherSubsidy || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "考核奖",
                text: _vm.salaryStandardInfo.assessmentAward || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "高温费",
                text: _vm.salaryStandardInfo.highTemperatureFee || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "社保基数",
                text: _vm.salaryStandardInfo.socialInsuranceBase || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "公积金基数",
                text: _vm.salaryStandardInfo.providentFundBase || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "养老基数",
                text: _vm.salaryStandardInfo.pensionBase || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "生育基数",
                text: _vm.salaryStandardInfo.fertilityBase || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "失业基数",
                text: _vm.salaryStandardInfo.unemploymentBase || "--",
                span: 8,
                required: "",
              },
            }),
            _c("col2-item", {
              attrs: {
                label: "工伤基数",
                text: _vm.salaryStandardInfo.workInjuryBase || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "salary-div" },
          [
            _c("col2-item", {
              attrs: {
                label: "医疗基数",
                text: _vm.salaryStandardInfo.medicalBase || "--",
                span: 8,
                required: "",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }