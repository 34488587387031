var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Salary-wrapper" },
    [
      _c("div", { staticClass: "employee_information_box" }, [
        _c(
          "div",
          { staticClass: "information_box_left" },
          [
            _c("v-uploader", {
              attrs: {
                operate: "none",
                action: _vm.headUploadURL,
                fileList: _vm.headPic,
                disabled: _vm.isDisabled,
              },
              on: {
                "update:fileList": function ($event) {
                  _vm.headPic = $event
                },
                "update:file-list": function ($event) {
                  _vm.headPic = $event
                },
              },
            }),
            _c("div", { staticClass: "information_box_main" }, [
              _c("div", { staticClass: "information_box_main_name" }, [
                _vm._v("\n          " + _vm._s(_vm.form.userName)),
                _c(
                  "span",
                  { staticClass: "information_box_main_name_number" },
                  [_vm._v("(工号" + _vm._s(_vm.form.jobNum) + ")")]
                ),
              ]),
              _c("div", { staticClass: "information_box_main_sex" }, [
                _c("div", { staticClass: "information_box_main_item" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.form.sex === 1 ? "男" : "女") +
                      "\n          "
                  ),
                ]),
                _c("div", { staticClass: "information_box_main_item" }, [
                  _vm._v(_vm._s(_vm.form.birthday)),
                ]),
                _c("div", { staticClass: "information_box_main_item" }, [
                  _vm._v(_vm._s(_vm.form.age)),
                ]),
              ]),
              _c("div", { staticClass: "information_box_main_company" }, [
                _c("div", { staticClass: "information_box_main_item" }, [
                  _vm._v(_vm._s(_vm.orgName)),
                ]),
                _c("div", { staticClass: "information_box_main_item" }, [
                  _vm._v(_vm._s(_vm.form.duty)),
                ]),
                _c("div", { staticClass: "information_box_main_item" }, [
                  _vm._v(_vm._s(_vm.form.jobRank)),
                ]),
              ]),
              _c("div", { staticClass: "information_box_main_phone" }, [
                _vm._v(_vm._s(_vm.form.phone)),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "information_box_right" },
          [
            _c(
              "el-radio-group",
              {
                staticClass: "tabs",
                on: { change: _vm.change },
                model: {
                  value: _vm.active,
                  callback: function ($$v) {
                    _vm.active = $$v
                  },
                  expression: "active",
                },
              },
              _vm._l(_vm.tabs, function (item, index) {
                return _c("el-radio-button", {
                  key: index,
                  attrs: { label: item.label, disabled: item.disabled },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            staticClass: "form_panel",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
            scopedSlots: _vm._u(
              [
                _vm.showEditBtn && _vm.active !== "工作信息"
                  ? {
                      key: "headerSlot",
                      fn: function () {
                        return [
                          _c("v-button", {
                            attrs: { text: "编辑" },
                            on: { click: _vm.editUserInfo },
                          }),
                          _c("v-button", {
                            attrs: { text: "返回" },
                            on: { click: _vm.goBack },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "saveBeforeSlot",
                  fn: function () {
                    return [
                      _vm.showConfirm && _vm.active !== "工作信息"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            attrs: { type: "success", text: "保存" },
                            on: { click: _vm.submitBefore },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "col2-detail",
            { staticClass: "col2-detail" },
            [
              _c("personal-info", {
                ref: "personalInfo",
                attrs: {
                  _form: _vm.personalDate,
                  sexOps: _vm.sexOps,
                  cardTypeOps: _vm.cardTypeOps,
                  nationOps: _vm.nationOps,
                  politicsStatusOps: _vm.politicsStatusOps,
                  maritalStatusOps: _vm.maritalStatusOps,
                  fertilityStatusOps: _vm.fertilityStatusOps,
                  educationDegreeOps: _vm.educationDegreeOps,
                  nativePlaceArray: _vm.nativePlaceArray,
                  residenceAddressArray: _vm.residenceAddressArray,
                  currentResidenceArray: _vm.currentResidenceArray,
                  isDisabled: _vm.isDisabled,
                  show: _vm.active == "个人信息",
                },
                on: { ongetTreeList: _vm.getTreeList },
              }),
              _vm.isShowTabs
                ? _c("employed-info", {
                    ref: "employedInfo",
                    attrs: {
                      _form: _vm.personalDate,
                      treeSelectData: _vm.treeSelectData,
                      treeData: _vm.treeData,
                      gradeOps: _vm.gradeOps,
                      setSalaryOps: _vm.setSalaryOps,
                      setIsPaymentOps: _vm.setIsPaymentOps,
                      isDisabled: _vm.isDisabled,
                      show: _vm.active == "录用信息",
                    },
                  })
                : _vm._e(),
              _vm.active == "录用信息"
                ? _c("div", { staticClass: "employed_info" })
                : _vm._e(),
              _vm.isShowTabs
                ? _c("salary-info", {
                    ref: "salaryInfo",
                    attrs: {
                      _form: _vm.personalDate,
                      salaryStandardInfo: _vm.salaryStandardInfo,
                      salaryGradeOps: _vm.salaryGradeOps,
                      isDisabled: _vm.isDisabled,
                      show: _vm.active == "薪资信息",
                    },
                  })
                : _vm._e(),
              _vm.active == "薪资信息"
                ? _c("div", { staticClass: "salary_info" })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "组织列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getOrgRelationListURL,
          extraParams: _vm.extraParams,
          headers: _vm.orgTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.orgList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.orgList = $event
          },
          "update:back-fill": function ($event) {
            _vm.orgList = $event
          },
          callback: _vm.onOrgSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "组织名称" },
                  model: {
                    value: _vm.searchParams.orgName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orgName", $$v)
                    },
                    expression: "searchParams.orgName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "组织标签" },
                      model: {
                        value: _vm.searchParams.orgTagId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgTagId", $$v)
                        },
                        expression: "searchParams.orgTagId",
                      },
                    },
                    "v-select2",
                    _vm.orgTagParams,
                    false
                  )
                ),
                _vm.form.roleType === "107"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: {
                            subjoin: _vm.roleExtraParams,
                            label: "角色",
                          },
                          model: {
                            value: _vm.searchParams.roleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "roleId", $$v)
                            },
                            expression: "searchParams.roleId",
                          },
                        },
                        "v-select2",
                        _vm.roleParams,
                        false
                      )
                    )
                  : _vm._e(),
                _vm.form.roleType !== "101"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "关联项目" },
                          model: {
                            value: _vm.searchParams.communityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityId", $$v)
                            },
                            expression: "searchParams.communityId",
                          },
                        },
                        "v-select2",
                        _vm.communityParams,
                        false
                      )
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }