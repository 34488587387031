var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("col2-block", { attrs: { title: "录用信息" } }, [
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "工号",
                      prop: "jobNum",
                      disabled: _vm.edit,
                      rules: [
                        {
                          required: true,
                          message: "请输入工号",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入工号",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.jobNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jobNum", $$v)
                        },
                        expression: "form.jobNum",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "所属部门",
                      prop: "orgId",
                      rules: [
                        {
                          required: true,
                          message: "请选择所属部门",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c("select-tree", {
                      attrs: {
                        value: _vm.form.orgId,
                        data: _vm.treeSelectData1,
                        placeholder: "查询所属部门",
                        treeData: _vm.treeData,
                        treeProps: _vm.treeProps,
                        highlightCurrent: true,
                        filterNodeMethod: _vm.filterNodeMethod,
                        "expand-on-click-node": false,
                        disabled: _vm.isDisabled,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.form, "orgId", $event)
                        },
                        "update:data": function ($event) {
                          _vm.treeSelectData1 = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "岗位",
                      prop: "duty",
                      rules: [
                        {
                          required: true,
                          message: "请输入岗位",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入岗位",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.duty,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "duty", $$v)
                        },
                        expression: "form.duty",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "职级",
                      prop: "jobRank",
                      rules: [
                        {
                          required: true,
                          validator: _vm.validateSalary,
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled: _vm.isDisabled,
                        options: _vm.gradeOps,
                        width: _vm.width,
                      },
                      model: {
                        value: _vm.form.jobRank,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jobRank", $$v)
                        },
                        expression: "form.jobRank",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "入职时间", prop: "jobEnterDate" } },
                  [
                    _c("v-datepicker", {
                      attrs: {
                        type: "date",
                        width: _vm.width,
                        format: "YYYY-MM-DD",
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.jobEnterDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "jobEnterDate", $$v)
                        },
                        expression: "form.jobEnterDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "试用期期限", prop: "probationPeriod" } },
                  [
                    _c("v-input-number", {
                      attrs: {
                        placeholder: "请输入试用期期限",
                        max: 99999999999999999999,
                        min: 0,
                        width: _vm.width1,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.probationPeriod,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "probationPeriod", $$v)
                        },
                        expression: "form.probationPeriod",
                      },
                    }),
                    _vm._v("\n        个月\n      "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "试用期薪资", prop: "probationSalary" } },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.setSalaryOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.probationSalary,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "probationSalary", $$v)
                        },
                        expression: "form.probationSalary",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "是否缴纳公积金",
                      prop: "providentFundFlag",
                    },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.setIsPaymentOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.providentFundFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "providentFundFlag", $$v)
                        },
                        expression: "form.providentFundFlag",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "是否缴纳社保",
                      prop: "socialSecurityFlag",
                    },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.setIsPaymentOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.socialSecurityFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "socialSecurityFlag", $$v)
                        },
                        expression: "form.socialSecurityFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "工龄",
                      prop: "workingAge",
                      disabled: _vm.edit,
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入工龄",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.workingAge,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "workingAge", $$v)
                        },
                        expression: "form.workingAge",
                      },
                    }),
                    _vm._v("\n        年\n      "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "入职推荐人",
                      prop: "referrer",
                      disabled: _vm.edit,
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入入职推荐人姓名",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.referrer,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "referrer", $$v)
                        },
                        expression: "form.referrer",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "备注",
                      prop: "remark",
                      disabled: _vm.edit,
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        type: "textarea",
                        width: _vm.width,
                        placeholder: "请输入内容",
                        maxlength: 20,
                        "show-word-limit": "",
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "remark", $$v)
                        },
                        expression: "form.remark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("col2-block", { attrs: { title: "工资卡信息" } }, [
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "收款人",
                      prop: "payee",
                      disabled: _vm.edit,
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入收款人姓名",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.payee,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payee", $$v)
                        },
                        expression: "form.payee",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "开户行", prop: "openingBank" } },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.bankDegreeOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.openingBank,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "openingBank", $$v)
                        },
                        expression: "form.openingBank",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "银行卡号",
                      prop: "bankCardNumber",
                      disabled: _vm.edit,
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入银行卡号",
                        width: _vm.width,
                        maxlength: 19,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.bankCardNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "bankCardNumber", $$v)
                        },
                        expression: "form.bankCardNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }