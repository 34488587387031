// 录用信息组件
<template>
  <div class="container" v-if="show">
    <col2-block title="录用信息">
      <div class="salary-div">
        <el-form-item
          label="工号"
          prop="jobNum"
          :disabled="edit"
          :rules="[{ required: true, message: '请输入工号', trigger: 'blur' }]"
        >
          <v-input
            v-model="form.jobNum"
            placeholder="请输入工号"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item
          label="所属部门"
          prop="orgId"
          :rules="[
            {
              required: true,
              message: '请选择所属部门',
              trigger: 'change',
            },
          ]"
        >
          <select-tree
            :value.sync="form.orgId"
            :data.sync="treeSelectData1"
            placeholder="查询所属部门"
            :treeData="treeData"
            :treeProps="treeProps"
            :highlightCurrent="true"
            :filterNodeMethod="filterNodeMethod"
            :expand-on-click-node="false"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="salary-div">
        <el-form-item
          label="岗位"
          prop="duty"
          :rules="[{ required: true, message: '请输入岗位', trigger: 'blur' }]"
        >
          <v-input
            v-model="form.duty"
            placeholder="请输入岗位"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item
          label="职级"
          prop="jobRank"
          :rules="[
            {
              required: true,
              validator: validateSalary,
              trigger: 'blur',
            },
          ]"
        >
          <v-select
            v-model="form.jobRank"
            :disabled="isDisabled"
            :options="gradeOps"
            :width="width"
          />
        </el-form-item>
      </div>
      <div class="salary-div">
        <el-form-item label="入职时间" prop="jobEnterDate">
          <v-datepicker
            v-model="form.jobEnterDate"
            type="date"
            :width="width"
            format="YYYY-MM-DD"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="试用期期限" prop="probationPeriod">
          <!-- <v-select
                  v-model="form.probationPeriod"
                  :options="deadlineOps"
                  :width="width"
                />              -->
          <v-input-number
            placeholder="请输入试用期期限"
            v-model="form.probationPeriod"
            :max="99999999999999999999"
            :min="0"
            :width="width1"
            :disabled="isDisabled"
          />
          个月
        </el-form-item>
      </div>
      <div class="salary-div">
        <el-form-item label="试用期薪资" prop="probationSalary">
          <v-select
            v-model="form.probationSalary"
            :options="setSalaryOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="是否缴纳公积金" prop="providentFundFlag">
          <v-select
            v-model="form.providentFundFlag"
            :options="setIsPaymentOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="salary-div">
        <el-form-item label="是否缴纳社保" prop="socialSecurityFlag">
          <v-select
            v-model="form.socialSecurityFlag"
            :options="setIsPaymentOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="工龄" prop="workingAge" :disabled="edit">
          <v-input
            v-model="form.workingAge"
            placeholder="请输入工龄"
            :width="width"
            :disabled="isDisabled"
          />
          年
        </el-form-item>
      </div>
      <div class="salary-div">
        <el-form-item label="入职推荐人" prop="referrer" :disabled="edit">
          <v-input
            v-model="form.referrer"
            placeholder="请输入入职推荐人姓名"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remark" :disabled="edit">
          <v-input
            type="textarea"
            v-model="form.remark"
            :width="width"
            placeholder="请输入内容"
            :maxlength="20"
            show-word-limit
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
    </col2-block>
    <col2-block title="工资卡信息">
      <div class="salary-div">
        <el-form-item label="收款人" prop="payee" :disabled="edit">
          <v-input
            v-model="form.payee"
            placeholder="请输入收款人姓名"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="开户行" prop="openingBank">
          <v-select
            v-model="form.openingBank"
            :options="bankDegreeOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="salary-div">
        <el-form-item label="银行卡号" prop="bankCardNumber" :disabled="edit">
          <v-input
            v-model="form.bankCardNumber"
            placeholder="请输入银行卡号"
            :width="width"
            :maxlength="19"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
    </col2-block>
  </div>
</template>

<script>
import { Col2Block, SelectTree } from "components/bussiness";
export default {
  name: "employedInfo",
  components: {
    Col2Block,
    SelectTree,
  },
  props: {
    // 接收父级传入的表单数据
    _form: {
      type: Object,
      default: {},
    },
    // 组件的显示隐藏
    show: {
      type: Boolean,
      default: true,
      width: 182,
    },
    // 查询所属部门数组
    treeSelectData: {
      type: Object,
      default: {},
    },
    // 所属部门树数据
    treeData: {
      type: Array,
      default: [],
    },
    // 职称
    gradeOps: {
      type: Array,
      default: [],
    },
    // 公积金缴纳
    setSalaryOps: {
      type: Array,
      default: [],
    },
    // 是否缴纳社保
    setIsPaymentOps: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {}, // 组件内部数据
      edit: false,
      width1: 150,
      width: 182,
      treeProps: {
        id: "id", // 必须
        parentId: "parentId",
        children: "childOrgList", // 必须
        label: "orgName", // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: "operateArr", // 必须
      },
      bankDegreeOps: [
        {
          text: "招商银行",
          value: "招商银行",
        },
        {
          text: "中国工商银行",
          value: "中国工商银行",
        },
        {
          text: "中国农业银行",
          value: "中国农业银行",
        },
        {
          text: "中国银行",
          value: "中国银行",
        },
        {
          text: "中国建设银行",
          value: "中国建设银行",
        },
        {
          text: "平安银行",
          value: "平安银行",
        },
        {
          text: "交通银行",
          value: "交通银行",
        },
        {
          text: "中信银行",
          value: "中信银行",
        },
        {
          text: "兴业银行",
          value: "兴业银行",
        },
        {
          text: "光大银行",
          value: "光大银行",
        },
        {
          text: "中国民生银行",
          value: "中国民生银行",
        },
        {
          text: "中国邮政储蓄银行",
          value: "中国邮政储蓄银行",
        },
        {
          text: "上海浦东发展银行",
          value: "上海浦东发展银行",
        },
        {
          text: "广发银行",
          value: "广发银行",
        },
        {
          text: "华夏银行",
          value: "华夏银行",
        },
        {
          text: "恒丰银行",
          value: "恒丰银行",
        },
        {
          text: "渤海银行",
          value: "渤海银行",
        },
      ],
      treeSelectData1: {},
      _bankCardNumber: '',
    };
  },
  computed: {},
  watch: {
    _form: function (newValue) {
      console.log(newValue, "newValue");
      if (newValue) {
        this.form = Object.assign({}, newValue);
      }
    },
    treeSelectData: function (newValue) {
      if (newValue) {
        this.treeSelectData1 = Object.assign({}, newValue);
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    /* 选择树控件 */
    filterNodeMethod(value, data) {
      // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      return data[this.treeProps.label].indexOf(value) !== -1;
    },
    validateSalary(rule, value, callback) {
      const salaryRegular = /\d+|\d.\d+|0\.\d+/;
      if (value === "") {
        callback(new Error("该项不能为空"));
      } else if (!salaryRegular.test(value)) {
        callback(new Error("格式不正确，请输入数字"));
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  .talent-div {
    display: flex;
    align-items: center;
  }
  .salary-div {
    display: flex;
    // align-items: center;
  }
  .salary-pad {
    padding-top: 20px;
  }
  .summary-salary {
    text-align: right;
    padding-bottom: 20px;
  }
  .col2-container {
    width: 1262px !important;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
