// 公司管理查看页面
<template>
  <div class="Salary-wrapper">
    <!-- 顶部员工基础信息部分 -->
    <div class="employee_information_box">
      <div class="information_box_left">
        <v-uploader
          operate="none"
          :action="headUploadURL"
          :fileList.sync="headPic"
          :disabled="isDisabled"
        />

        <div class="information_box_main">
          <div class="information_box_main_name">
            {{ form.userName
            }}<span class="information_box_main_name_number"
              >(工号{{ form.jobNum }})</span
            >
          </div>
          <div class="information_box_main_sex">
            <div class="information_box_main_item">
              {{ form.sex === 1 ? "男" : "女" }}
            </div>
            <div class="information_box_main_item">{{ form.birthday }}</div>
            <div class="information_box_main_item">{{ form.age }}</div>
          </div>
          <div class="information_box_main_company">
            <div class="information_box_main_item">{{ orgName }}</div>
            <div class="information_box_main_item">{{ form.duty }}</div>
            <div class="information_box_main_item">{{ form.jobRank }}</div>
          </div>
          <div class="information_box_main_phone">{{ form.phone }}</div>
        </div>
      </div>

      <div class="information_box_right">
        <el-radio-group class="tabs" v-model="active" @change="change">
          <el-radio-button
            v-for="(item, index) in tabs"
            :key="index"
            :label="item.label"
            :disabled="item.disabled"
          ></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <form-panel
      ref="form"
      v-bind="config"
      :form="form"
      @update="update"
      class="form_panel"
    >
      <template #headerSlot v-if="showEditBtn && active !== '工作信息'">
        <v-button text="编辑" @click="editUserInfo"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail class="col2-detail">
        <!-- 为了满足三个tabar页面 分别保存的功能 三个tabar分别分为三个组件 -->
        <!-- 个人信息 -->
        <personal-info
          :_form="personalDate"
          :sexOps="sexOps"
          :cardTypeOps="cardTypeOps"
          :nationOps="nationOps"
          :politicsStatusOps="politicsStatusOps"
          :maritalStatusOps="maritalStatusOps"
          :fertilityStatusOps="fertilityStatusOps"
          :educationDegreeOps="educationDegreeOps"
          :nativePlaceArray="nativePlaceArray"
          :residenceAddressArray="residenceAddressArray"
          :currentResidenceArray="currentResidenceArray"
          @ongetTreeList="getTreeList"
          ref="personalInfo"
          :isDisabled="isDisabled"
          :show="active == '个人信息'"
        ></personal-info>
        <!-- 录用信息 -->
        <employed-info
          :_form="personalDate"
          v-if="isShowTabs"
          ref="employedInfo"
          :treeSelectData="treeSelectData"
          :treeData="treeData"
          :gradeOps="gradeOps"
          :setSalaryOps="setSalaryOps"
          :setIsPaymentOps="setIsPaymentOps"
          :isDisabled="isDisabled"
          :show="active == '录用信息'"
        ></employed-info>
        <div class="employed_info" v-if="active == '录用信息'"></div>
        <!-- 薪资信息 -->
        <salary-info
          :_form="personalDate"
          ref="salaryInfo"
          v-if="isShowTabs"
          :salaryStandardInfo="salaryStandardInfo"
          :salaryGradeOps="salaryGradeOps"
          :isDisabled="isDisabled"
          :show="active == '薪资信息'"
        ></salary-info>
        <div class="salary_info" v-if="active == '薪资信息'"></div>
      </col2-detail>
      <!-- 工作信息 -->
      <!-- 工作信息 展示额charts图 -->
      <!-- <div class="work_info" v-if="active == '工作信息'">
        <div class="top-date">
          周期：
          <v-datepicker
            style="width: auto"
            v-model="expressAcceptRateStartDate"
            :clearable="false"
            :maxDate="expressAcceptRateEndDate"
            type="month"
            @change="workInfoStatisticsDateChange"
          />&nbsp;&nbsp;至&nbsp;&nbsp;<v-datepicker
            style="width: auto"
            v-model="expressAcceptRateEndDate"
            :clearable="false"
            :minDate="expressAcceptRateStartDate"
            type="month"
            @change="workInfoStatisticsDateChange"
          />
        </div>
        <div
          class="overview-box"
          v-for="(item, index) in workInfoStatList"
          :key="index"
        >
          <data-container class="overview-body" type="1" :title="item.title">
            <div class="body-box">
              <div class="canvasHeight" :id="`expressYearCount_` + index"></div>
            </div>
          </data-container>
          <data-container
            class="overview-body flex2"
            type="1"
            :title="item.title + '完成率'"
          >
            <div class="body-box">
              <div
                class="canvasHeight"
                :id="`expressAcceptRate_` + index"
              ></div>
            </div>
          </data-container>
        </div>
      </div> -->
      <template #saveBeforeSlot>
        <!-- <v-button v-if="showReturn" text="返回" @click="returnClick" /> -->
        <v-button
          type="success"
          v-loading="loading"
          text="保存"
          @click="submitBefore"
          v-if="showConfirm && active !== '工作信息'"
        />
      </template>
    </form-panel>
    <multi-select
      title="组织列表"
      :isShow.sync="isShow"
      :searchUrl="getOrgRelationListURL"
      :extraParams="extraParams"
      :headers="orgTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="orgList"
      @callback="onOrgSelect"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.orgName" label="组织名称"></v-input>
        <v-select2
          v-model="searchParams.orgTagId"
          v-bind="orgTagParams"
          label="组织标签"
        ></v-select2>
        <v-select2
          v-if="form.roleType === '107'"
          v-model="searchParams.roleId"
          :subjoin="roleExtraParams"
          v-bind="roleParams"
          label="角色"
        ></v-select2>
        <v-select2
          v-if="form.roleType !== '101'"
          v-model="searchParams.communityId"
          v-bind="communityParams"
          label="关联项目"
        ></v-select2>
      </template>
    </multi-select>
  </div>
</template>

<script>
import {
  getInitInfoURL,
  getEnterOrgTreeListURL,
  getRoleTypeURL,
  getOrganizeURL,
  getRolesURL,
  getOrgRelationListURL,
  getDropDownSearchTagURL,
  addManageUserURL,
  updateManageUserURL,
  updateTypeURL,
  getViewOauthURL,
  getStoreListURL,
  getUserInfoURL,
  resetPwgURL,
  unlockedURL,
  batchURL,
  // 新接口
  updateEnterpriseUserInfoURL,
  addEnterpriseUserInfoURL,
  getEnterpriseUserInfoDetailURL,
  queryURL,
  headUploadURL,
  getWorkInfoStatisticsUrl,
} from "./api";
import { statusOps, statusMap, sexOps } from "./map";
import { regionParams } from "common/select2Params";
import { vUploader } from "../../../components/control/index";
import {
  Col2Detail,
  Col2Block,
  Col2Item,
  ChosenListPanel,
  SelectTree,
  MultiSelect,
  AreaSelect,
} from "components/bussiness";
import { vCheckbox } from "components/control/index";
import { communityParams } from "common/select2Params";
import {
  drawedChart,
  linearGradientColor,
  pieChartSeriesOpts,
} from "common/echartsUtils";

import personalInfo from "./components/personal_info.vue";
import employedInfo from "./components/employed_info.vue";
import salaryInfo from "./components/salary_info.vue";

export default {
  name: "OrganizeUserDeatil",
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    AreaSelect,
    SelectTree,
    vCheckbox,
    MultiSelect,
    ChosenListPanel,
    personalInfo,
    employedInfo,
    salaryInfo,
    vUploader,
  },
  data() {
    let year = new Date().getFullYear().toString();
    return {
      showConfirm: true,
      regionParams,
      headUploadURL,
      width: 182,
      width1: 150,
      pageLoading: false, // 页面加载..
      loading: false, // 保存按钮加载..
      id: "",
      newId: "", // 获取新接口详情
      orgId: "", // 组织id
      orgParentId: "", // 组织父id
      from: "",
      submitContentType: "application/json;charset=UTF-8",
      config: {
        queryUrl: getUserInfoURL,
      },
      nativePlaceArray: [],
      residenceAddressArray: [],
      currentResidenceArray: [],
      form: {
        // old
        country: "",
        userName: "",
        sex: undefined, // 新加 性别
        birthday: "", // 新加 生日
        emailAddress: "", // 新加 个人邮箱
        phone: "", // 手机号
        loginName: undefined,
        pwd: "",
        pwd2: "",
        duty: "",
        roleType: undefined,
        roleId: "",
        oauth: "",
        storeIds: "",
        organizeId: "",
        // headIcon: undefined,
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        isSystemAdded: this.isSystemAdded,

        // new
        fromFlag: "2",
        nativePlaceProvince: "", //籍贯
        nativePlaceCity: "",
        nativePlaceArea: "",
        registeredResidenceProvince: "", //户口所在地
        registeredResidenceCity: "",
        registeredResidenceArea: "",
        registeredResidenceAddress: "",
        currentResidenceProvince: "", //现在居住地
        currentResidenceCity: "",
        currentResidenceArea: "",
        currentResidenceAddress: "",
        tenantId: "",
        userName: "",
        sex: undefined,
        cardType: undefined,
        cardNum: "",
        birthday: "",
        nation: undefined,
        maritalStatus: undefined,
        fertilityStatus: undefined,
        politicalStatus: undefined,
        educationDegree: undefined,
        major: "",
        graduateSchool: "",
        firstMajor: "",
        firstGraduateSchool: "",
        firstEducationDegree: undefined,
        personalEmailAddress: "",
        mobileNum: "",
        interviewDate: "",
        fileUrlList: [],
        jobNum: "",
        orgId: "",
        jobRank: undefined,
        jobEnterDate: "",
        probationPeriod: undefined,
        robationSalary: undefined,
        providentFundFlag: undefined,
        socialSecurityFlag: undefined,
        salaryGrade: undefined,
        probationSalary: undefined,
        accountStatus: 0, // 账号状态,默认有效
        accountValidDate: "",
        adminDuty: "",
        bankCardNumber: "", // 银行卡号
      },
      oldForm: {
        // 原有页面的字段 - accountMgrForm
        id: "",
        userName: "",
        sex: "",
        birthday: "",
        emailAddress: "",
        phone: "",
        loginName: undefined,
        pwd: "",
        pwd2: "",
        duty: "",
        roleType: undefined,
        roleId: "",
        oauth: "",
        storeIds: "",
        organizeId: "",
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        isSystemAdded: this.isSystemAdded,
        userId: "",
      },
      newForm: {
        userId: "",
        // 新页面的字段 - 2022.03.29 更改
        id: "", // 此id与老接口获取详情id并不相同
        country: "",
        // new
        fromFlag: "2",
        nativePlaceProvince: "", //籍贯
        nativePlaceCity: "",
        nativePlaceArea: "",
        registeredResidenceProvince: "", //户口所在地
        registeredResidenceCity: "",
        registeredResidenceArea: "",
        registeredResidenceAddress: "",
        currentResidenceProvince: "", //现在居住地
        currentResidenceCity: "",
        currentResidenceArea: "",
        currentResidenceAddress: "",
        tenantId: "",
        userName: "",
        sex: undefined,
        cardType: undefined,
        cardNum: "",
        birthday: "",
        nation: undefined,
        maritalStatus: undefined,
        fertilityStatus: undefined,
        politicalStatus: undefined,
        educationDegree: undefined,
        major: "",
        graduateSchool: "",
        firstMajor: "",
        firstGraduateSchool: "",
        firstEducationDegree: undefined,
        personalEmailAddress: "",
        mobileNum: "",
        interviewDate: "",
        fileUrlList: [],
        jobNum: "",
        orgId: "",
        jobRank: undefined,
        jobEnterDate: "",
        probationPeriod: undefined,
        robationSalary: undefined,
        providentFundFlag: undefined,
        socialSecurityFlag: undefined,
        salaryGrade: undefined,
        probationSalary: undefined,
        accountStatus: "", // 账号状态,默认有效
        accountValidDate: "",
        adminDuty: "",
        bankCardNumber: "", // 银行卡号
        educationAdmissionDate: undefined, // 最高学历开始时间
        educationGraduationDate: undefined, // 最高学历结束时间
        firstEducationAdmissionDate: undefined, // 第一学历开始时间
        firstEducationGraduationDate: undefined, // 第一学历结束时间
        emergencyContact: "", // 紧急联系人姓名
        emergencyContactMobile: "", // 紧急联系人电话号码
        workingAge: 0, // 工龄
        referrer: "", // 入职推荐人
        remark: "", // 备注
        openingBank: "", // 开户银行
        payee: "", // 收款人姓名
        age: "", // 年龄,
        headPicName: [],
      },
      edit: false,
      salaryStandardInfo: {}, //员工基础信息
      setSalaryOps: [],
      setIsPaymentOps: [],
      gradeOps: [], //职级
      salaryGradeOps: [], //薪资档级
      cardTypeOps: [], //证件类型
      politicsStatusOps: [], //政治面貌
      nationOps: [], //民族
      sexOps: sexOps(), //性别
      fertilityStatusOps: [],
      maritalStatusOps: [],
      educationDegreeOps: [], //学历
      nativePlaceOps: [], //籍贯
      areaList: [], // 户口所在地
      /* select2控件 */
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: "orgName_",
          value: "orgId_",
        },
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: "roleName_",
          value: "roleId_",
        },
      },
      oauthList: [], // 授权物业服务列表
      oauthStoreList: [], // 授权门店列表
      /* select-tree 控件 */
      treeSelectData: {}, // 所选树节点的数据
      treeProps: {
        id: "id", // 必须
        parentId: "parentId",
        children: "childOrgList", // 必须
        label: "orgName", // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: "operateArr", // 必须
      },
      treeData: [],
      orgType: 2, // 组织类型
      /* 多选框控件 */
      getOrgRelationListURL,
      isShow: false,
      communityParams,
      searchParams: {
        orgName: "",
        orgTagId: "",
        roleId: undefined,
        communityId: "",
      },
      orgTagParams: {
        searchUrl: getDropDownSearchTagURL,
        request: {
          text: "tagName",
          value: "tagId",
        },
        response: {
          text: "tagName",
          value: "id",
        },
      },
      responseKey: {
        id: "id",
        name: "text",
      },
      orgList: [],
      /* 辅助状态 */
      oauthIds: [], // 授权物业ids
      oauthStoreIds: [], // 授权门店ids
      roleType: "", // 记录初始的角色类型
      userName: "", // 记录初始的用户名
      loginName: "", // 记录初始的登录名
      isBack: false, // 获取页面详情时,账号类型修改不清空相关数据
      roleTypeOps: [], // 账号类型
      statusOps,
      statusMap,
      showEditBtn: false,
      treeType: undefined,
      active: "个人信息",
      maxDate: new Date(), // 时间选择器最大选择时间
      headerImageUrl: undefined, // 头像上传替换url
      workInfoStatisticsBeginTime: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 > 10
          ? new Date().getMonth() + 1
          : "0" + (new Date().getMonth() + 1)
      }`,
      workInfoStatisticsEndTime: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 > 10
          ? new Date().getMonth() + 1
          : "0" + (new Date().getMonth() + 1)
      }`,
      workInfoStatList: [], // 获取到的员工统计数据
      propertyOrderDate: year,
      pieDefault: {
        radius: ["55%", "70%"],
        center: ["30%", "50%"],
        label: {
          normal: {
            show: false,
          },
        },
      },
      chartsAry: [],
      expressAcceptRateStartDate: `${new Date().getFullYear()}-01`,
      expressAcceptRateEndDate: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1
      }`,
      bankDegreeOps: [
        {
          text: "招商银行",
          value: "招商银行",
        },
        {
          text: "中国工商银行",
          value: "中国工商银行",
        },
        {
          text: "中国农业银行",
          value: "中国农业银行",
        },
        {
          text: "中国银行",
          value: "中国银行",
        },
        {
          text: "中国建设银行",
          value: "中国建设银行",
        },
        {
          text: "平安银行",
          value: "平安银行",
        },
        {
          text: "交通银行",
          value: "交通银行",
        },
        {
          text: "中信银行",
          value: "中信银行",
        },
        {
          text: "兴业银行",
          value: "兴业银行",
        },
        {
          text: "光大银行",
          value: "光大银行",
        },
        {
          text: "中国民生银行",
          value: "中国民生银行",
        },
        {
          text: "中国邮政储蓄银行",
          value: "中国邮政储蓄银行",
        },
        {
          text: "上海浦东发展银行",
          value: "上海浦东发展银行",
        },
        {
          text: "广发银行",
          value: "广发银行",
        },
        {
          text: "华夏银行",
          value: "华夏银行",
        },
        {
          text: "恒丰银行",
          value: "恒丰银行",
        },
        {
          text: "渤海银行",
          value: "渤海银行",
        },
      ],
      orgName: "", // 所属部门名称
      personalDate: {}, // 个人信息
      headPic: [],

      isDisabled: true, // 是否禁止修改
    };
  },
  computed: {
    isShowTabs() {
      return this.$route.query.showTabs ? false : true;
    },
    isEdit() {
      return this.$route.query.id;
    },
    tabs() {
      let arr_1 = [
        {
          label: "个人信息",
          disabled: false,
        },
        {
          label: "录用信息",
          disabled: false,
        },
        {
          label: "薪资信息",
          disabled: false,
        },
        // {
        //   label: "工作信息",
        //   disabled: false,
        // },
      ];
      let arr_2 = [{ label: "个人信息", disabled: false }];
      let tabs = this.$route.query.showTabs ? arr_2 : arr_1;
      return tabs;
    },
    /* 全局变量 */
    userInfo() {
      return this.$store.state.userInfo;
    },
    // editMobile () { // 手机号控件是否禁用
    //   return this.$store.getters.getPermission('editMobile')
    // },
    /* 表单提交and请求 */
    extraParams() {
      let params = { roleType: this.form.roleType };
      if (this.form.roleType === "101") {
        params = {
          roleType: this.form.roleType,
          communityId: this.form.organizeId,
        };
      }
      return params;
    },
    submitUrl() {
      return this.id
        ? this.from
          ? addManageUserURL
          : updateManageUserURL
        : addManageUserURL;
    },
    submitUrl2() {
      return this.id
        ? this.from
          ? addEnterpriseUserInfoURL
          : updateEnterpriseUserInfoURL
        : addEnterpriseUserInfoURL;
    },
    submitMethod() {
      return this.id ? (this.from ? "POST" : "PUT") : "POST";
    },
    /* select2 控件 */
    roleExtraParams() {
      // 管理范围
      return {
        roleType: this.form.roleType,
      };
    },
    rolesExtraParams() {
      // 角色
      let organizeId = "";
      if (this.userInfo.userType === "101") {
        organizeId = this.userInfo.orgId;
      }
      return {
        roleType: this.form.roleType,
        organizeId: organizeId || this.form.organizeId,
      };
    },
    /* vcheckbox 多选项 */
    oauthShow() {
      // 授权物业服务
      let arr = ["100", "101", "102"];
      return arr.indexOf(this.form.roleType) > -1;
    },
    oauthStoreShow() {
      let arr = ["101"];
      return arr.indexOf(this.form.roleType) > -1;
    },
    /* 多选框 */
    orgTableHeader() {
      // 多选框表头
      let show = this.form.roleType === "107";
      return [
        {
          prop: "orgName",
          label: "组织名称",
        },
        {
          prop: "orgTagNames",
          label: "组织标签",
        },
        {
          prop: "communityNames",
          label: "关联项目",
        },
        {
          prop: "roleName",
          label: "角色名称",
          show: show,
        },
      ];
    },
    responseParams() {
      // 多选框响应字段
      let responseParams1 = {
        id: "orgId",
        name: "orgName",
      };

      let responseParams2 = {
        id: "id",
        name: ["orgName", "roleName"],
      };

      return this.form.roleType === "101" ? responseParams1 : responseParams2;
    },
    /* 过滤 */
    roleTypeFilterOps() {
      // 2/3 物业管理公司/社区,账号只能选集团以下的角色, 1 平台职能部门 只能选 集团+系统
      if (Number(this.orgType) === 1) {
        return this.roleTypeOps.filter(
          (v) => v.value === "100" || v.value === "106"
        );
      } else if (Number(this.orgType) === 2 || Number(this.orgType) === 3) {
        return this.roleTypeOps.filter(
          (v) => v.value !== "100" && v.value !== "106"
        );
      }
      return this.roleTypeOps;
    },
    /* 控件的显隐以及禁用 */
    loginNameDisabled() {
      // 登录名禁止修改
      let arr = ["100", "102", "101", "107"];
      return (
        arr.indexOf(this.form.roleType) < 0 ||
        (this.id && this.id.length > 0 && !this.from)
      );
    },
  },
  // watch: {
  //   defaultOrganizeId (newValue) {
  //     this.form.organizeId = newValue
  //   }
  // },
  created() {
    this.id = this.$route.query.id || "";
    this.orgId = this.$route.query.orgId || "";
    this.newId = this.$route.query.newId || "";
    console.log(
      "treeType",
      this.$route.query.treeType,
      typeof this.$route.query.treeType
    );
    this.treeType = this.$route.query.treeType || "";
    if (this.$route.query.isLook) {
      this.showConfirm = false;
    }
    if (this.$route.query.isShowEditBtn) {
      this.showEditBtn = true;
    }
    if (this.newId) {
      this.newForm.id = this.newId;
    }
    // 获取统计数据开始结束时间
    this.getWorkInfoStatisticsTime();
  },
  mounted() {
    const { id } = this.$route.query;
    /** 以下走嵌入页面逻辑 */
    if (this.$route.query.from == "talent") {
      this.from = "talent";
      this.config.queryUrl = queryURL;
      this.$refs.form.getData({ id });
      this.$setBreadcrumb("新增");
    } else {
      this.id && this.$refs.form.getData({ userId: this.id });
      this.id
        ? this.$route.query.isLook
          ? this.$setBreadcrumb("查看")
          : this.$setBreadcrumb("编辑")
        : this.$setBreadcrumb("新增");
      // if(this.$route.query.isLook){
      //   this.$setBreadcrumb('查看')
      // }
    }
    this.getSelectList();
    // 获取工作统计数据
    // this.getTreeList()
    this.getRoleType();
  },
  methods: {
    // 获取员工工作信息统计方法
    getWorkInfoStatistics(id) {
      let params = {
        userId: id,
        beginTime: this.expressAcceptRateStartDate,
        endTime: this.expressAcceptRateEndDate,
      };
      let _this = this;
      this.$axios.post(getWorkInfoStatisticsUrl, { ...params }).then((res) => {
        if (res.status === 100 && res.data) {
          this.workInfoStatList = res.data;
          //  _this.drawEchartRingS(res.data[0], 0);
          // _this.drawEchartLineS(res.data[0], 0);
          res.data.forEach((item, index) => {
            _this.drawEchartRingS(item, index);
            _this.drawEchartLineS(item, index);
          });
        }
      });
    },
    // 绘制echarts 环形图方法
    drawEchartRingS(value, _index) {
      // 获取 统计总数  已关闭数  已完成数 未完成数  统计标题 标题  各自数据
      let {
        totalRecordCount,
        closedCount,
        finishedCount,
        unfinishedCount,
        statisticsTitle,
        title,
      } = value;
      let seriesData = {
        name: title,
        data: [
          {
            name: "已关闭",
            value: closedCount,
          },
          {
            name: "已完成",
            value: finishedCount,
          },
          {
            name: "未完成",
            value: unfinishedCount,
          },
        ],
        ...this.pieDefault,
      };
      let option = {
        color: ["#52D09E", "#FFC750", "#7ac0d1"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          right: "5px",
          top: "center",
          formatter: function (name) {
            let oa = option.series.data;
            let num = 0;
            for (let n = 0; n < oa.length; n++) {
              num += oa[n].value;
            }
            for (let i = 0; i < option.series.data.length; i++) {
              if (name === oa[i].name) {
                return (
                  name +
                  "  " +
                  (num === 0 ? 0 : ((oa[i].value / num) * 100).toFixed(2)) +
                  "%" +
                  "  " +
                  oa[i].value
                );
              }
            }
          },
        },
        title: {
          text: totalRecordCount || 0,
          subtext: statisticsTitle,
          left: "29%",
          top: "center",
          textAlign: "center",
          textStyle: {
            fontWeight: "normal",
            fontSize: 32,
            color: "#1B8CF2",
          },
          subtextStyle: {
            fontSize: 14,
            color: "#333",
          },
        },
        series: pieChartSeriesOpts(seriesData),
      };
      setTimeout(() => {
        let chart = drawedChart("expressYearCount_" + _index, option);
        this.chartsAry.push(chart);
      }, 600);
    },
    // 绘制echarts 折线图方法
    drawEchartLineS(value, _index) {
      let { monthlyStatistics = [], statisticsTitle = [] } = value;
      let monthList = [];
      monthList.push(this.mapArrayFloatToPercentMonth(monthlyStatistics));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            label: {
              backgroundColor: "#6a7985",
            },
            lineStyle: {
              type: "dashed",
            },
          },
          formatter: function (params) {
            var s = params[0].name + "<br>";
            params.forEach(function (item, index) {
              s =
                s +
                item.seriesName +
                ": " +
                (item.value === null ? "-" : item.value + "%") +
                "<br>";
            });
            return s;
          },
        },
        grid: {
          top: 30,
          left: 15,
          right: 30,
          bottom: 30,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          data: monthList,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}%",
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
            bottom: 0,
            height: 20,
            backgroundColor: "rgba(242,242,242, 0.5)",
            fillerColor: "rgba(170,215,255, .5)",
            handleStyle: {
              color: "rgba(27,140,242,1)",
            },
          },
        ],
        series: [
          {
            name: statisticsTitle,
            symbol: "circle",
            type: "line",
            smooth: true,
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: "#8ec6ad",
              },
            },
            areaStyle: {
              normal: {
                color: linearGradientColor(
                  "rgba(216,178,255,0.5)",
                  "rgba(251,248,255,0.5"
                ),
              },
            },
            data: this.mapArrayFloatToPercentCompletion(monthlyStatistics),
          },
        ],
      };

      setTimeout(() => {
        let chart = drawedChart("expressAcceptRate_" + _index, option);
        this.chartsAry.push(chart);
      }, 600);
    },
    // 处理数组的值改为百分比 - 完成率
    mapArrayFloatToPercentCompletion(array) {
      return array.map((item) =>
        (parseFloat(item.completion) * 100).toFixed(2)
      );
    },
    // 处理数组的值改为百分比 - 月份
    mapArrayFloatToPercentMonth(array) {
      array.map((item) => {
        return item.recordMonth;
      });
    },
    // 修改周期时间,重新获取数据
    workInfoStatisticsDateChange() {
      this.getWorkInfoStatistics(this.id);
    },
    // 获取统计数据开始结束时间
    getWorkInfoStatisticsTime() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month === 1) {
        this.WorkInfoStatisticsBeginTime = `${year}-0${month}`;
        this.WorkInfoStatisticsEndTime = `${year - 1}-12`;
      } else {
        this.WorkInfoStatisticsBeginTime = `${year}-${
          month > 10 ? month : "0" + month
        }`;
        this.WorkInfoStatisticsEndTime = `${year}-${
          month > 10 ? month - 1 : "0" + (month - 1)
        }`;
      }
    },
    editUserInfo() {
      const { id, newId } = this.$route.query;
      this.showConfirm = true;
      this.showEditBtn = false;
      // this.$setBreadcrumb("编辑");
      this.isDisabled = false;
      this.$setBreadcrumb({
        reset: true,
        breadcrumb: ["通用中心", "账号管理", "公司管理", "编辑"],
      });
    },
    getbasicInfo(data, echo) {
      if (data) {
        this.getbasicInfoList();
      } else {
        this.salaryStandardInfo = {};
      }

      if (!echo) {
        console.log(data, echo);
      }
    },
    getbasicInfoList() {
      let params = {
        tenantId: this.form.tenantId,
        salaryGrade: this.form.salaryGrade,
      };
      this.$axios
        .get(getSalaryStandardByTenantAndGradeURL, { params: params })
        .then((res) => {
          if (res.status === 100 && res.data) {
            this.salaryStandardInfo = res.data;
          } else {
            this.form.salaryGrade = undefined;
            this.salaryStandardInfo = {};
          }
        });
    },
    getSelectList() {
      // 其余下拉选项数据
      this.$axios.get(getInitInfoURL).then((res) => {
        if (res.status === 100 && res.data) {
          const {
            educationDegreeList,
            nationList,
            cardTypeList,
            politicalStatusList,
            fertilityList,
            isMarryList,
            jobRankList,
            yesOrNoList,
            salaryGradeList,
            probationSalaryList,
          } = res.data;
          this.educationDegreeOps = educationDegreeList.map((item) => ({
            text: item,
            value: item,
          }));
          this.nationOps = nationList.map((item) => ({
            text: item,
            value: item,
          }));
          this.cardTypeOps = cardTypeList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.politicsStatusOps = politicalStatusList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.fertilityStatusOps = fertilityList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.maritalStatusOps = isMarryList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.gradeOps = jobRankList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.setIsPaymentOps = yesOrNoList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.salaryGradeOps = salaryGradeList.map((item, index) => ({
            text: item,
            value: item,
          }));
          this.setSalaryOps = probationSalaryList.map((item, index) => ({
            text: item,
            value: item,
          }));
        }
      });
    },
    getTreeList() {
      let that = this;
      // 所属部门树数据
      this.$axios
        .get(
          `${getEnterOrgTreeListURL}?orgType=${this.orgType}&&tenantId=${this.form.tenantId}`
        )
        .then((res) => {
          if (res.status === 100 && res.msg === "ok") {
            this.treeData = res.data;
            console.log("treeData+++", res.data);
            that.orgName = res.data[0].orgName;
            this.keyTree += 1;
          }
        });
    },
    /* 选择树控件 */
    filterNodeMethod(value, data) {
      // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      return data[this.treeProps.label].indexOf(value) !== -1;
    },
    getRoleType() {
      // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === "100") {
          const { data } = res;
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id,
            };
          });
          console.log(this.form.roleType);
          // !this.form.roleType && (this.form.roleType = data[0].id)
        }
      });
    },
    validatorOrgId(rule, value, callback) {
      // 所属组织
      if (this.form.roleType !== "107") {
        callback();
      } else if (this.orgList.length === 0) {
        callback(new Error("请选择所属组织"));
      } else {
        callback();
      }
    },
    validatePass1(rule, value, callback) {
      // 验证登录密码
      if (value === "") {
        callback(new Error("请输入用户密码"));
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error("密码长度8到12个字符!"));
      } else {
        callback();
      }
    },
    validatePass(rule, value, callback) {
      // 验证再次输入的密码
      if (value === "") {
        callback(new Error("请确认用户密码"));
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error("密码长度8到12个字符!"));
      } else if (value !== this.form.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    /** 辅助类方法 */
    inputDefault(e) {
      // 阻止默认事件
      for (let i = 0; i < 3; i += 1) {
        this.$refs["index" + i].onmousedown = function () {
          if (e && e.preventDefault) {
            // 现代浏览器阻止默认事件
            e.preventDefault();
          } else {
            // IE阻止默认事件
            window.event.returnValue = false;
          }
          return false;
        };
      }
    },
    getEmptyValue(val, emptyVal) {
      // 对空值进行判断
      if (val === undefined || val === "" || val === null) {
        return emptyVal;
      } else if (val === 0 || val === "0") {
        return 0;
      } else if (val === false) {
        return false;
      }
    },
    roleTypeChange() {
      // 账号类型
      if (this.isBack) {
        this.isBack = false;
        return false;
      }
      this.orgList = [];
      this.oauthList = [];
      this.oauthIds = [];
      this.oauthStoreList = [];
      this.oauthStoreIds = [];
      this.$refs.form.clearValidate();
    },
    organizeChange(val) {
      // 管理范围
      if (this.oauthShow) {
        // 如果是授权物业
        if (val && val.id) {
          this.getViewOauthList(val.id);
        } else {
          this.oauthList = [];
          this.oauthIds = [];
        }
      }
      if (this.oauthStoreShow) {
        // 如果是授权门店
        if (val && val.id) {
          this.getViewOauthStoreList(val.id);
        } else {
          this.oauthStoreList = [];
          this.oauthStoreIds = [];
        }
      }
    },
    authSelect(val, item, index) {
      if (val) {
        this.oauthIds.push(item.id);
      } else {
        let index = this.oauthIds.indexOf(item.id);
        this.oauthIds.splice(index, 1);
      }
    },
    authStoreSelect(val, item, index) {
      // 授权门店
      if (val) {
        this.oauthStoreIds.push(item.id);
      } else {
        let index = this.oauthStoreIds.indexOf(item.id);
        this.oauthStoreIds.splice(index, 1);
      }
    },
    onOrgSelect(data) {
      // 组织多选框内容
      console.log(data, "多选框选择内容");
    },
    /* 接口类 */
    upload2Request() {
      this.pageLoading = true;
      this.$axios
        .get(`${getEnterpriseUserInfoDetailURL}?id=${this.newId}`)
        .then((res) => {
          if (res.status === "100" || res.status === 100) {
            const { data } = res;
            this.upload2(data);
          }
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    async requestOldSubmit(params) {
      this.loading = true;
      let config = {
        method: this.submitMethod,
        url: this.submitUrl,
        headers: {
          "Content-Type": this.submitContentType,
        },
        data: params,
        transformRequest: [
          function (data) {
            return JSON.stringify(data);
          },
        ],
      };
      this.$axios(config).then(async (res) => {
        if (res.status === 100 || res.status === "100") {
          // let data = res.data
          // let msg = res.msg
          console.log("1. 老接口请求成功");
          let { data, msg } = res;
          let result = await this.submitSuccess(data, msg);
          console.log(
            this.orgId,
            this.orgParentId,
            "6.orgParentId 和 this.orgId"
          );
          if (!result) {
            return;
          }
          this.$message.success("保存成功");
          this.goBack();
        } else {
          this.loading = false;
          // this.submitError && this.submitError(res)
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    async requestNewSubmit(params) {
      console.log("3. 新表接口开始调");
      let result = false;
      let res = await this.$axios.post(this.submitUrl2, params);
      console.log("4. 新表接口调成功");
      this.loading = false;
      if (Number(res.status) === 100 && res.msg === "ok") {
        console.log(
          JSON.stringify(this.treeSelectData),
          "5. 修改orgId和orgParentId"
        );
        this.orgId = this.treeSelectData[this.treeProps.id];
        this.orgParentId = this.treeSelectData[this.treeProps.parentId];
        result = true;
      }
      return result;
    },
    getRoleType() {
      // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === "100") {
          const { data } = res;
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id,
            };
          });
          console.log(this.form.roleType);
          // !this.form.roleType && (this.form.roleType = data[0].id)
        }
      });
    },
    requestToAllConfig(id) {
      // 2.1 开启邑生活物管端APP待办项设置的请求接口
      this.$axios.post(`${updateTypeURL}?userId=${id}`).then((res) => {
        if (res.status === 100) {
          console.log("成功了");
        }
      });
    },
    getViewOauthList(id) {
      // 其余控件方法 - 管理范围 - 授权物业
      let params = {
        organizeId: id,
        userType: this.form.roleType,
      };
      // this.id && (params.userId = this.id)
      if (this.id && !this.from) {
        params.userId = this.id;
      }
      this.$axios.post(getViewOauthURL, params).then((res) => {
        if (res.status === "100") {
          let oauthList = [];
          let ids = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.selected || item.name === "集团报事") {
                ids.push(item.id);
              }
              oauthList.push({
                id: item.id,
                name: item.name,
                checked:
                  item.name === "集团报事" ? true : Number(item.selected) === 1,
              });
            });
          this.oauthList = oauthList;
          this.oauthIds = ids;
        }
      });
    },
    getViewOauthStoreList(id) {
      // 其余控件方法-管理范围-授权门店
      let data = {
        communityId: id,
        userType: this.form.roleType,
      };
      if (this.id && !this.from) {
        data.userId = this.id;
      }
      // (this.id&&!this.from) && (data.userId = this.id)
      this.$axios.get(getStoreListURL, { params: data }).then((res) => {
        if (res.status === 100) {
          let oauthList = [];
          let ids = [];
          res.data &&
            res.data.forEach((item) => {
              if (item.selected) {
                ids.push(item.id);
              }
              oauthList.push({
                id: item.id,
                name: item.name,
                checked: Number(item.selected) === 1,
              });
            });
          this.oauthStoreList = oauthList;
          this.oauthStoreIds = ids;
        }
      });
    },
    resetPwd() {
      // 重置密码
      this.$axios
        .put(resetPwgURL, this.$qs.stringify({ userId: this.id }))
        .then((res) => {
          if (res.status === 100) {
            this.$alert(res.data);
          }
        });
    },
    unlockID() {
      // 解锁
      this.$axios
        .post(unlockedURL, {
          userId: this.id,
          userName: this.loginName,
        })
        .then((res) => {
          if (Number(res.status) === 100) {
            this.$alert("账号解锁成功！");
          }
        });
    },
    updateStatus(status) {
      // 关闭or开启账号
      // 操作日志添加操作对象 规则：userName-loginName
      // let dataObject = dataArr.join(';')
      let dataObject = `${this.userName}-${this.loginName}`;

      let _this = this;
      let postData = {
        userIds: this.id,
        status, // 0 关闭 1 开启
        dataObject: dataObject,
      };
      _this
        .$axios({
          method: "put",
          url: batchURL,
          data: postData,
        })
        .then((res) => {
          if (res.status === 100) {
            _this.$refs.form.getData({ userId: this.id });
            _this.$message({
              type: "success",
              message: "操作成功",
              center: true,
            });
          }
        });
    },

    /* 其余控件方法 */
    toSelectOrg() {
      // 打开组织多选框
      this.isShow = true;
    },
    update(data) {
      if (this.from == "talent") {
        Object.keys({ ...this.newForm }).forEach((key) => {
          if (key === "id") {
            // form字段id为旧id,不能覆盖
            return;
          }
          this.form[key] =
            data[key] || this.getEmptyValue(data[key], this.form[key]);
          this.form.talentUserId = this.$route.query.id;
        });
        this.form.phone = data.mobileNum;
        this.$set(this.newForm, "id", this.newId);
        // 级联选择器
        this.nativePlaceArray = [
          data.nativePlaceProvince,
          data.nativePlaceCity,
          data.nativePlaceArea,
        ];
        this.residenceAddressArray = [
          data.registeredResidenceProvince,
          data.registeredResidenceCity,
          data.registeredResidenceArea,
        ];
        this.currentResidenceArray = [
          data.currentResidenceProvince,
          data.currentResidenceCity,
          data.currentResidenceArea,
        ];
      } else {
        this.isBack = true;
        /**
         * 老旧接口，无详细业务，且后端无档期查询接口逻辑，因而保留原有代码
         * 请求详情接口为最早的old接口，与第一次旧接口保存字段并非一一对应，所以衍生出此代码
         *
         */
        // 1. 字段匹配值
        this.form.id = data.id;
        this.form.sex = [1, 2].includes(+data.sex) ? data.sex : undefined; // 0 过滤掉
        this.form.birthday = data.birthday;
        this.form.emailAddress = data.emailAddress || "";
        this.form.duty = data.duty;
        this.form.loginName = data.loginName;
        this.form.phone = data.phone;
        this.form.desensitization = !data.desensitization;
        this.form.userName = data.userName;
        // this.newseeUserName = data.nsUserName
        this.form.organizeId = data.orgId;
        this.form.roleId = data.roleId;
        this.form.roleType = JSON.stringify(data.userType);
        // 2. 记录部分字段的初始状态
        this.userName = data.userName;
        this.loginName = data.loginName;
        this.roleType = JSON.stringify(data.userType);
        // 3. 组织多选框的列表根据权限显示不同数据
        if (this.form.roleType === "107") {
          this.orgList =
            data.belongOrgList && data.belongOrgList.length > 0
              ? data.belongOrgList.map((item) => ({
                  text: item.orgRoleName,
                  id: item.roleId,
                  obj: item,
                }))
              : [];
        }
        if (this.form.roleType === "101") {
          this.orgList =
            data.belongOrgList && data.belongOrgList.length > 0
              ? data.belongOrgList.map((item) => ({
                  text: item.orgName,
                  id: String(item.orgId),
                  obj: item,
                }))
              : [];
        }
        // 4. 请求新接口详情
        this.upload2Request();
        /** end */
        // Object.keys({ ...this.form, id: '' }).forEach(key => {
        //   this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
        // })
      }
    },
    upload2(data) {
      if (!data) {
        return;
      }
      Object.keys({ ...this.newForm }).forEach((key) => {
        if (key === "id") {
          // form字段id为旧id,不能覆盖
          return;
        }
        this.form[key] =
          data[key] || this.getEmptyValue(data[key], this.form[key]);
      });
      this.headPic = [
        {
          url: this.form.headPicName,
        },
      ];
      this.personalDate = Object.assign({}, this.form);
      this.$set(this.newForm, "id", this.newId);
      // 级联选择器
      this.nativePlaceArray = [
        data.nativePlaceProvince,
        data.nativePlaceCity,
        data.nativePlaceArea,
      ];
      this.residenceAddressArray = [
        data.registeredResidenceProvince,
        data.registeredResidenceCity,
        data.registeredResidenceArea,
      ];
      this.currentResidenceArray = [
        data.currentResidenceProvince,
        data.currentResidenceCity,
        data.currentResidenceArea,
      ];
    },
    /* 表单请求之前and请求成功 */
    async submitBefore() {
      // 1. 调保存接口前
      // 0. 先验证表单 注释内容存在问题
      // let result = this.$refs.form.validate()
      // console.log('this.$refs.form.$refs.form',this.$refs.form.$refs.form)
      if (this.active === "个人信息") {
        let personalInfoDate = this.$refs.personalInfo.form;
        if (
          personalInfoDate.fileUrlList &&
          personalInfoDate.fileUrlList.length > 0
        ) {
          let fileUrlList = [];
          personalInfoDate.fileUrlList.forEach((item) => {
            fileUrlList.push(item.url);
          });
          personalInfoDate.fileUrlList = fileUrlList;
        }
        console.log("personalInfoDate", personalInfoDate);
        this.form = Object.assign(this.form, personalInfoDate);
      } else if (this.active === "录用信息") {
        let employedInfoDate = this.$refs.employedInfo.form;
        if (employedInfoDate.bankCardNumber) {
          const salaryRegular = /[^\d]/g;
          let value = salaryRegular.test(employedInfoDate.bankCardNumber);
          if(value) {
            this.$message.error("银行卡号输入错误,保存失败！");
            return;
          }
        }
        let treeSelectData1 = this.$refs.employedInfo.treeSelectData1;
        console.log("employedInfoDate", employedInfoDate);
        this.form = Object.assign(this.form, employedInfoDate);
        this.treeSelectData = Object.assign({}, treeSelectData1);
      } else if (this.active === "薪资信息") {
        let salaryInfoDate = this.$refs.salaryInfo.form;
        console.log("salaryInfoDate", salaryInfoDate);
        this.form = Object.assign(this.form, salaryInfoDate);
      }
      this.form.headPicName = this.headPic[0];
      console.log("this.form", this.form);
      this.$refs.form.$refs.form.validate((result) => {
        if (!result) {
          this.$message.error("保存失败！");
          return;
        }
        // 1. 深度克隆表单数据
        const oldParams = _.cloneDeep(this.form);
        this.setOldParams(oldParams);
      });
    },
    setOldParams(params) {
      // 2. 设置老接口参数
      // 1. 去除掉值为undefined的字段
      let oldForm = {};
      let keys = Object.keys(this.oldForm);
      keys.forEach((k) => {
        if (params[k] !== undefined) {
          oldForm[k] = params[k];
        }
      });
      // 2. 字段类型修改
      oldForm.desensitization = oldForm.desensitization ? 0 : 1;
      // 3. 字段名称修改
      oldForm.mobileNum = oldForm.phone;
      oldForm.adminDuty = oldForm.duty;
      oldForm.password = oldForm.pwd;
      oldForm.userType = oldForm.roleType;
      oldForm.orgId = oldForm.organizeId;
      // 4. 字段值填充
      this.orgList.length > 0 &&
        (oldForm.orgIds = this.orgList.map((item) => item.id));
      this.oauthIds.length > 0 && (oldForm.subIds = this.oauthIds);
      this.oauthStoreIds.length > 0 && (oldForm.storeIds = this.oauthStoreIds);
      // 4. 区分新增和编辑，编辑需要保留原有字段, 新增则不需要
      if (!this.id || this.from) {
        delete oldForm.phone;
        delete oldForm.duty;
        delete oldForm.pwd;
        delete oldForm.pwd2;
        delete oldForm.roleType;
        delete oldForm.organizeId;
        delete oldForm.oauth;
      } else {
        oldForm.userId = oldForm.id;
      }
      // 5. 权限
      if (oldForm.userType !== "101") {
        delete oldForm.orgIds;
      }
      if (this.form.roleType === "107") {
        oldForm.roleIds = this.orgList.map((item) => item.id);
        this.orgList[0].obj.orgId &&
          (oldForm.orgId = this.orgList[0].obj.orgId); // 组织账号默认
        oldForm.roleId = oldForm.roleIds[0];
      }
      // 6. 未知业务,暂不清楚为何如此写
      oldForm.logingName = oldForm.loginName;
      // 添加操作对象参数 规则：账号名称-登录名
      let dataObject = `${oldForm.userName}`;
      if (oldForm.loginName) {
        dataObject += `-${oldForm.loginName}`;
      }
      oldForm.dataObject = dataObject;
      // 7. 调请求接口,注意老接口请求成功后再请求新接口
      this.requestOldSubmit(oldForm);
    },
    async submitSuccess(data, msg) {
      // 3. 老接口保存成功后
      console.log("0. 开始调新接口");
      let onoff = await this.setNewParams();
      if (onoff) {
        console.log("5. 新表接口正常");
      }
      if (this.id && !this.from) {
        this.openAllConfig(this.id);
      } else {
        this.openAllConfig(data);
        // 当角色权限不为区域、集团、项目时，data可能是账号名的提示
        data && !/^(\d|\w|-)+$/.test(data) && (await this.$alert(data));
      }
      return true;
    },
    async openAllConfig(id) {
      // 4-1. 物管端APP待办项设置————区域、项目、集团
      // 100-区域、101-项目、102-集团
      let arr = ["100", "101", "102"];
      if (this.id && !this.from) {
        if (arr.includes(this.form.roleType) && !arr.includes(this.roleType)) {
          // 编辑 - 如果修改后的账号类型符合要求,且原先的账户类型不符合要求,则调接口
          this.requestToAllConfig(id);
        }
      } else if (arr.includes(this.form.roleType)) {
        // 新增 - 选择的账号类型符合要求，则调接口
        this.requestToAllConfig(id);
      }
    },
    async setNewParams() {
      // 4-2. 新接口
      const params = _.cloneDeep(this.form);
      let newForm = {};
      Object.keys({ ...this.newForm }).forEach((k) => {
        if (k === "id") {
          // 此处id不能用form,而必须是newForm的id
          newForm[k] = this.newForm[k];
          return;
        }
        newForm[k] = params[k];
        if (k === "mobileNum") {
          newForm[k] = params["phone"];
        }
        if (k === "adminDuty") {
          newForm[k] = params["duty"];
        }
      });
      if (this.from == "talent") {
        newForm.talentUserId = this.$route.query.id;
      }
      const result = await this.requestNewSubmit(newForm);
      return result;
    },
    change(e) {
      if (e === "工作信息") {
        this.getWorkInfoStatistics(this.id);
      }
    },
    // 头像上传 方法
    handleAvatarSuccess(res, file) {
      this.form.headPicName = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isImg = file.type.indexOf("image") > -1;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("头像只能是图片格式");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
  },
};
</script>
<style lang="scss" scoped>
.mar-l {
  margin-left: 8px;
}
.f-wh {
  width: 520px;
}
.f-wh2 {
  width: 355px;
}
.title-panel-container {
  margin-bottom: 10px;
}
.Salary-wrapper {
  overflow: auto;
  .talent-div {
    display: flex;
    align-items: center;
  }
  .salary-div {
    display: flex;
    // align-items: center;
  }
  .salary-pad {
    padding-top: 20px;
  }
  .summary-salary {
    text-align: right;
    padding-bottom: 20px;
  }
  .col2-container {
    width: 1262px !important;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
.form_panel {
  margin-top: 10px;
}

.employee_information_box {
  display: flex;
  justify-content: space-between;
  margin: 0px 14px;
  padding-bottom: 10px;
  border-bottom: 1px solid #aaa;

  .information_box_left {
    display: flex;

    &_img {
      width: 120px;
      height: 120px;
    }

    .information_box_main {
      margin-left: 30px;

      &_name {
        font-size: 36px;
        font-weight: bold;
        color: #333;
        display: flex;
        align-items: baseline;
        line-height: 1;
        padding: 0 8px;

        .information_box_main_name_number {
          font-size: 14px;
          color: #aaa;
        }
      }

      .information_box_main_sex,
      .information_box_main_company {
        font-size: 14px;
        line-height: 20px;
        color: #aaa;
        display: flex;
        align-items: center;
        margin-top: 6px;

        .information_box_main_item {
          padding: 0px 8px;
          border-right: 1px solid #aaa;
        }
      }

      .information_box_main_phone {
        font-size: 14px;
        line-height: 20px;
        color: #aaa;
        margin-top: 6px;
        text-align: left;
        padding: 0px 8px;
      }
    }
  }

  .information_box_right {
    display: flex;
    align-items: flex-end;
  }
}
.work_info {
  width: 100%;

  .top-date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.canvasHeight {
  height: 284px;
}

.overview-box {
  display: flex;
  margin-top: 20px;
  width: 100%;
  .overview-body {
    flex: 1;
    margin-right: 20px;
  }
  .overview-body:last-child {
    margin-right: 0;
  }
  .flex2 {
    flex: 2;
  }
}
.body-box {
  padding: 20px;
  .top-date {
    text-align: right;
    .v-datepicker-container {
      display: inline-block;
    }
  }
}

.PlatFormDataList-wrapper {
  overflow: auto;
  background: #fff;
  text-align: left;
  padding: 0 20px;
}
.overview-title {
  font-size: 14px;
  .overview-title-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: #7ac943;
  }
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.data-list {
  overflow: hidden;
  margin-top: 20px;
}
.overview-box {
  display: flex;
  margin-top: 20px;
  .overview-body {
    flex: 1;
    margin-right: 20px;
  }
  .overview-body:last-child {
    margin-right: 0;
  }
  .flex2 {
    flex: 2;
  }
}
</style>
