// 个人信息组件
<template>
  <div class="container" v-if="show">
    <col2-block title="基本信息">
      <div class="talent-div">
        <el-form-item
          label="所属公司"
          prop="tenantId"
          :rules="[
            {
              required: true,
              message: '请选择所属公司',
              trigger: 'change',
            },
          ]"
        >
          <v-select2
            ref="regionNameRef"
            v-model="form.tenantId"
            placeholder="输入公司名称"
            v-bind="regionParams"
            :width="width"
            :disabled="isDisabled"
            @onChange="tenantChange"
          />
        </el-form-item>
        <el-form-item
          label="员工姓名"
          prop="userName"
          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
        >
          <v-input
            v-model="form.userName"
            placeholder="请输入员工姓名"
            :maxlength="20"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item
          label="性别"
          prop="sex"
          :rules="[
            { required: true, message: '请选择性别', trigger: 'change' },
          ]"
        >
          <v-select
            :disabled="isDisabled"
            v-model="form.sex"
            :options="sexOps"
            :width="width"
          />
        </el-form-item>
        <el-form-item
          label="证件类型"
          prop="cardType"
          :rules="[
            {
              required: true,
              message: '请选择证件类型',
              trigger: 'change',
            },
          ]"
        >
          <v-select
            :disabled="isDisabled"
            v-model="form.cardType"
            :options="cardTypeOps"
          ></v-select>
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item
          label="证件号码"
          prop="cardNum"
          :rules="[
            { required: true, validator: validateCard, trigger: 'blur' },
          ]"
        >
          <v-input
            v-model="form.cardNum"
            placeholder="请输入证件号"
            :maxlength="18"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="出生日期" prop="birthday">
          <v-datepicker
            v-model="form.birthday"
            type="date"
            format="YYYY-MM-DD"
            :maxDate="new Date()"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item label="民族" prop="nation">
          <v-select
            v-model="form.nation"
            filterable
            :options="nationOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="政治面貌" prop="politicalStatus">
          <v-select
            v-model="form.politicalStatus"
            :options="politicsStatusOps"
            :disabled="isDisabled"
          ></v-select>
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item label="婚姻状况" prop="maritalStatus">
          <v-select
            v-model="form.maritalStatus"
            :options="maritalStatusOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="生育状况" prop="fertilityStatus">
          <v-select
            v-model="form.fertilityStatus"
            :options="fertilityStatusOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item
          label="籍贯"
          prop="nativePlaceArea"
          :rules="[
            { required: true, message: '请选择籍贯', trigger: 'change' },
          ]"
        >
          <area-select
            :codes.sync="nativePlaceArray"
            :detail-addr="false"
            @change="nativePlaceChange"
            :disabled="isDisabled"
          >
          </area-select>
        </el-form-item>

        <div style="display: flex">
          <el-form-item label="户口所在地" prop="registeredResidenceArea">
            <area-select
              :codes.sync="residenceAddressArray"
              :detail-addr="false"
              @change="areaChange"
              :disabled="isDisabled"
            >
            </area-select>
          </el-form-item>
          <el-form-item
                label-width="20px"
                prop="registeredResidenceAddress"
              >
                <v-input
                  v-model="form.registeredResidenceAddress"
                  placeholder="请输入详细地址"
                  :disabled="isDisabled"
                />
              </el-form-item>
        </div>
      </div>
      <div class="talent-div">
        <div style="display: flex">
          <el-form-item
            label="现居住地"
            prop="currentResidenceArea"
          >
            <area-select
              :codes.sync="currentResidenceArray"
              :detail-addr="false"
              @change="currentResidenceChange"
              :disabled="isDisabled"
            >
            </area-select>
          </el-form-item>
          <el-form-item
                label-width="20px"
                prop="currentResidenceAddress"
              >
                <v-input
                  v-model="form.currentResidenceAddress"
                  placeholder="请输入详细地址"
                  :disabled="isDisabled"
                />
              </el-form-item>
        </div>
      </div>
    </col2-block>
    <col2-block title="教育信息">
      <div class="talent-div">
        <el-form-item
          label="最高学历"
          prop="educationDegree"
        >
          <v-select
            v-model="form.educationDegree"
            :options="educationDegreeOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item
          label="毕业院校"
          prop="graduateSchool"
        >
          <v-input
            v-model="form.graduateSchool"
            placeholder="请输入毕业院校"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item
          label="所学专业"
          prop="major"
        >
          <v-input
            v-model="form.major"
            placeholder="请输入所学专业"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="学习时间" prop="educationAdmissionDate">
          <v-datepicker
            :maxDate="maxDate"
            type="rangedatetimer"
            sPlaceholder="开始时间"
            ePlaceholder="结束时间"
            :startTime.sync="form.educationAdmissionDate"
            :endTime.sync="form.educationGraduationDate"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item label="第一学历" prop="firstEducationDegree">
          <v-select
            v-model="form.firstEducationDegree"
            :options="educationDegreeOps"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="毕业院校" prop="firstGraduateSchool">
          <v-input
            v-model="form.firstGraduateSchool"
            placeholder="请输入毕业院校"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item label="所学专业" prop="firstMajor">
          <v-input
            v-model="form.firstMajor"
            placeholder="请输入所学专业"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="学习时间" prop="firstEducationAdmissionDate">
          <v-datepicker
            :maxDate="maxDate"
            type="rangedatetimer"
            sPlaceholder="开始时间"
            ePlaceholder="结束时间"
            :startTime.sync="form.firstEducationAdmissionDate"
            :endTime.sync="form.firstEducationGraduationDate"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
    </col2-block>
    <col2-block title="联系信息">
      <div class="talent-div">
        <el-form-item
          label="电子邮箱"
          prop="personalEmailAddress"
        >
          <v-input
            v-model="form.personalEmailAddress"
            placeholder="请输入个人邮箱"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="phone"
          :rules="[
            { required: true, validator: validatePhone, trigger: 'blur' },
          ]"
        >
          <v-input
            v-model="form.phone"
            placeholder="请输入手机号"
            :maxlength="11"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
      <div class="talent-div">
        <el-form-item label="紧急联系人" prop="emergencyContact">
          <v-input
            v-model="form.emergencyContact"
            placeholder="请输入紧急联系人姓名"
            :maxlength="10"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
        <el-form-item label="联系电话" prop="emergencyContactMobile">
          <v-input
            v-model="form.emergencyContactMobile"
            placeholder="请输入联系人电话"
            :maxlength="11"
            :width="width"
            :disabled="isDisabled"
          />
        </el-form-item>
      </div>
    </col2-block>
    <col2-block title="证件信息">
      <div class="salary-div">
        <el-form-item
          label="上传证件信息"
          prop="fileUrlList"
        >
          <v-uploader
            :action="uploadURL"
            :fileList.sync="form.fileUrlList"
            :limit="10"
            :onlyImage="true"
            :fileSize="0.2"
            :disabled="isDisabled"
            :beforeRemove="beforeRemoveImgs"
            :stopDelete="isDisabled"
          >
            <template #tip>
              <div>
                注：1、可上传员工身份证、学位证等图片信息，支持JPG(JPEG)、GIF、PNG、BMP，单张不得超过200K
              </div>
              <div style="padding-left: 28px">
                2、支持10张以内图片上传，可一次上传多张
              </div>
            </template>
          </v-uploader>
        </el-form-item>
      </div>
    </col2-block>
  </div>
</template>

<script>
import { vUploader } from "components/control";
import { regionParams } from "common/select2Params";
import { Col2Block, AreaSelect } from "components/bussiness";
import { uploadURL } from "../api";
import {
  mobileRegular,
  idNumberRegular,
  emailAddressRegular,
} from "common/regular";
export default {
  name: "personalInfo",
  components: {
    vUploader,
    Col2Block,
    AreaSelect,
  },
  props: {
    // 接收父级传入的表单数据
    _form: {
      type: Object,
      default: {},
    },
    // 组件的显示隐藏
    show: {
      type: Boolean,
      default: true,
    },
    treeType: {
      type: String,
    },
    // 性别选择对象
    sexOps: {
      type: Array,
      default: [],
    },
    // 证件类型
    cardTypeOps: {
      type: Array,
      default: [],
    },
    // 民族选择对象
    nationOps: {
      type: Array,
      default: [],
    },
    // 政治面貌选择对象
    politicsStatusOps: {
      type: Array,
      default: [],
    },
    // 婚姻选择对象
    maritalStatusOps: {
      type: Array,
      default: [],
    },
    // 生育状况选择对象
    fertilityStatusOps: {
      type: Array,
      default: [],
    },
    // 教育状况选择对象
    educationDegreeOps: {
      type: Array,
      default: [],
    },
    // 籍贯选择
    nativePlaceArray: {
      type: Array,
      default: [],
    },
    // 户口选择
    residenceAddressArray: {
      type: Array,
      default: [],
    },
    // 现居住地
    currentResidenceArray: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: 182,
      maxDate: new Date(), // 时间选择器最大选择时间
      uploadURL: uploadURL,
      regionParams,
      form: {}, // 组件内部数据
    };
  },
  computed: {},
  watch: {
    _form: function (newValue) {
      if (newValue) {
        let fileUrlList = [];
        if(newValue.fileUrlList && newValue.fileUrlList.length > 0) {
          newValue.fileUrlList.forEach(item => {
            fileUrlList.push({
              url: item
            })
          });
          newValue.fileUrlList = fileUrlList
        }
        this.form = Object.assign({}, newValue);
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 证件号码验证
    validateCard(rule, value, callback) {
      if (value === "") {
        callback(new Error("请填写证件号"));
      } else if (this.form.cardType == "身份证") {
        if (!idNumberRegular.test(value)) {
          callback(new Error("证件号不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    // 电子邮箱验证
    validateEmail(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入电子邮箱"));
        return;
      }
      if (!emailAddressRegular.test(value)) {
        callback(new Error("邮箱格式不正确"));
      } else {
        callback();
      }
    },
    // 手机号码验证
    validatePhone(rule, value, callback) {
      let regExp = mobileRegular;
      if (!regExp.test(value)) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    },
    nativePlaceChange(val) {
      //籍贯
      if (val.area) {
        this.form.nativePlaceProvince = val.province.code;
        this.form.nativePlaceCity = val.city.code;
        this.form.nativePlaceArea = val.area.code;
      } else {
        this.form.nativePlaceProvince = "";
        this.form.nativePlaceCity = "";
        this.form.nativePlaceArea = "";
      }
    },
    areaChange(val) {
      // 户口所在地
      if (val.area) {
        this.form.registeredResidenceCity = val.city.code;
        this.form.registeredResidenceArea = val.area.code;
        this.form.registeredResidenceProvince = val.province.code;
      } else {
        this.form.registeredResidenceCity = "";
        this.form.registeredResidenceArea = "";
        this.form.registeredResidenceProvince = "";
      }
    },
    currentResidenceChange(val) {
      //现居住地
      if (val.area) {
        this.form.currentResidenceProvince = val.province.code;
        this.form.currentResidenceCity = val.city.code;
        this.form.currentResidenceArea = val.area.code;
      } else {
        this.form.currentResidenceProvince = "";
        this.form.currentResidenceCity = "";
        this.form.currentResidenceArea = "";
      }
    },
    // 修改公司名称
    tenantChange(option, echo) {
      this.$emit("ongetTreeList");
      if (!echo) {
        this.form.salaryGrade = undefined;
        this.form.tenantChange = "";
      }
    },
    //
    beforeRemoveImgs() {
      console.log(this.isDisabled, "this.isDisabled");
      if (this.isDisabled) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  .talent-div {
    display: flex;
    align-items: center;
  }
  .salary-div {
    display: flex;
    // align-items: center;
  }
  .salary-pad {
    padding-top: 20px;
  }
  .summary-salary {
    text-align: right;
    padding-bottom: 20px;
  }
  .col2-container {
    width: 1262px !important;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
