var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("col2-block", { attrs: { title: "基本信息" } }, [
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "所属公司",
                      prop: "tenantId",
                      rules: [
                        {
                          required: true,
                          message: "请选择所属公司",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          ref: "regionNameRef",
                          attrs: {
                            placeholder: "输入公司名称",
                            width: _vm.width,
                            disabled: _vm.isDisabled,
                          },
                          on: { onChange: _vm.tenantChange },
                          model: {
                            value: _vm.form.tenantId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "tenantId", $$v)
                            },
                            expression: "form.tenantId",
                          },
                        },
                        "v-select2",
                        _vm.regionParams,
                        false
                      )
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "员工姓名",
                      prop: "userName",
                      rules: [
                        {
                          required: true,
                          message: "请输入姓名",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入员工姓名",
                        maxlength: 20,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.userName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "userName", $$v)
                        },
                        expression: "form.userName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "性别",
                      prop: "sex",
                      rules: [
                        {
                          required: true,
                          message: "请选择性别",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled: _vm.isDisabled,
                        options: _vm.sexOps,
                        width: _vm.width,
                      },
                      model: {
                        value: _vm.form.sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sex", $$v)
                        },
                        expression: "form.sex",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "证件类型",
                      prop: "cardType",
                      rules: [
                        {
                          required: true,
                          message: "请选择证件类型",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        disabled: _vm.isDisabled,
                        options: _vm.cardTypeOps,
                      },
                      model: {
                        value: _vm.form.cardType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cardType", $$v)
                        },
                        expression: "form.cardType",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "证件号码",
                      prop: "cardNum",
                      rules: [
                        {
                          required: true,
                          validator: _vm.validateCard,
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入证件号",
                        maxlength: 18,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.cardNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cardNum", $$v)
                        },
                        expression: "form.cardNum",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "出生日期", prop: "birthday" } },
                  [
                    _c("v-datepicker", {
                      attrs: {
                        type: "date",
                        format: "YYYY-MM-DD",
                        maxDate: new Date(),
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.birthday,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "birthday", $$v)
                        },
                        expression: "form.birthday",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "民族", prop: "nation" } },
                  [
                    _c("v-select", {
                      attrs: {
                        filterable: "",
                        options: _vm.nationOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.nation,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "nation", $$v)
                        },
                        expression: "form.nation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "政治面貌", prop: "politicalStatus" } },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.politicsStatusOps,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.politicalStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "politicalStatus", $$v)
                        },
                        expression: "form.politicalStatus",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "婚姻状况", prop: "maritalStatus" } },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.maritalStatusOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.maritalStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "maritalStatus", $$v)
                        },
                        expression: "form.maritalStatus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "生育状况", prop: "fertilityStatus" } },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.fertilityStatusOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.fertilityStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "fertilityStatus", $$v)
                        },
                        expression: "form.fertilityStatus",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "籍贯",
                      prop: "nativePlaceArea",
                      rules: [
                        {
                          required: true,
                          message: "请选择籍贯",
                          trigger: "change",
                        },
                      ],
                    },
                  },
                  [
                    _c("area-select", {
                      attrs: {
                        codes: _vm.nativePlaceArray,
                        "detail-addr": false,
                        disabled: _vm.isDisabled,
                      },
                      on: {
                        "update:codes": function ($event) {
                          _vm.nativePlaceArray = $event
                        },
                        change: _vm.nativePlaceChange,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "户口所在地",
                          prop: "registeredResidenceArea",
                        },
                      },
                      [
                        _c("area-select", {
                          attrs: {
                            codes: _vm.residenceAddressArray,
                            "detail-addr": false,
                            disabled: _vm.isDisabled,
                          },
                          on: {
                            "update:codes": function ($event) {
                              _vm.residenceAddressArray = $event
                            },
                            change: _vm.areaChange,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "20px",
                          prop: "registeredResidenceAddress",
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: {
                            placeholder: "请输入详细地址",
                            disabled: _vm.isDisabled,
                          },
                          model: {
                            value: _vm.form.registeredResidenceAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "registeredResidenceAddress",
                                $$v
                              )
                            },
                            expression: "form.registeredResidenceAddress",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "talent-div" }, [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "现居住地",
                        prop: "currentResidenceArea",
                      },
                    },
                    [
                      _c("area-select", {
                        attrs: {
                          codes: _vm.currentResidenceArray,
                          "detail-addr": false,
                          disabled: _vm.isDisabled,
                        },
                        on: {
                          "update:codes": function ($event) {
                            _vm.currentResidenceArray = $event
                          },
                          change: _vm.currentResidenceChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "20px",
                        prop: "currentResidenceAddress",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入详细地址",
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.form.currentResidenceAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "currentResidenceAddress", $$v)
                          },
                          expression: "form.currentResidenceAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("col2-block", { attrs: { title: "教育信息" } }, [
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "最高学历", prop: "educationDegree" } },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.educationDegreeOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.educationDegree,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "educationDegree", $$v)
                        },
                        expression: "form.educationDegree",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "毕业院校", prop: "graduateSchool" } },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入毕业院校",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.graduateSchool,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "graduateSchool", $$v)
                        },
                        expression: "form.graduateSchool",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "所学专业", prop: "major" } },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入所学专业",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.major,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "major", $$v)
                        },
                        expression: "form.major",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "学习时间",
                      prop: "educationAdmissionDate",
                    },
                  },
                  [
                    _c("v-datepicker", {
                      attrs: {
                        maxDate: _vm.maxDate,
                        type: "rangedatetimer",
                        sPlaceholder: "开始时间",
                        ePlaceholder: "结束时间",
                        startTime: _vm.form.educationAdmissionDate,
                        endTime: _vm.form.educationGraduationDate,
                        disabled: _vm.isDisabled,
                      },
                      on: {
                        "update:startTime": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "educationAdmissionDate",
                            $event
                          )
                        },
                        "update:start-time": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "educationAdmissionDate",
                            $event
                          )
                        },
                        "update:endTime": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "educationGraduationDate",
                            $event
                          )
                        },
                        "update:end-time": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "educationGraduationDate",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "第一学历", prop: "firstEducationDegree" },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.educationDegreeOps,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.firstEducationDegree,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "firstEducationDegree", $$v)
                        },
                        expression: "form.firstEducationDegree",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "毕业院校", prop: "firstGraduateSchool" } },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入毕业院校",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.firstGraduateSchool,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "firstGraduateSchool", $$v)
                        },
                        expression: "form.firstGraduateSchool",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "所学专业", prop: "firstMajor" } },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入所学专业",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.firstMajor,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "firstMajor", $$v)
                        },
                        expression: "form.firstMajor",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "学习时间",
                      prop: "firstEducationAdmissionDate",
                    },
                  },
                  [
                    _c("v-datepicker", {
                      attrs: {
                        maxDate: _vm.maxDate,
                        type: "rangedatetimer",
                        sPlaceholder: "开始时间",
                        ePlaceholder: "结束时间",
                        startTime: _vm.form.firstEducationAdmissionDate,
                        endTime: _vm.form.firstEducationGraduationDate,
                        disabled: _vm.isDisabled,
                      },
                      on: {
                        "update:startTime": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "firstEducationAdmissionDate",
                            $event
                          )
                        },
                        "update:start-time": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "firstEducationAdmissionDate",
                            $event
                          )
                        },
                        "update:endTime": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "firstEducationGraduationDate",
                            $event
                          )
                        },
                        "update:end-time": function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "firstEducationGraduationDate",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("col2-block", { attrs: { title: "联系信息" } }, [
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "电子邮箱", prop: "personalEmailAddress" },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入个人邮箱",
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.personalEmailAddress,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "personalEmailAddress", $$v)
                        },
                        expression: "form.personalEmailAddress",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "联系电话",
                      prop: "phone",
                      rules: [
                        {
                          required: true,
                          validator: _vm.validatePhone,
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入手机号",
                        maxlength: 11,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "talent-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "紧急联系人", prop: "emergencyContact" } },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入紧急联系人姓名",
                        maxlength: 10,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.emergencyContact,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "emergencyContact", $$v)
                        },
                        expression: "form.emergencyContact",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "联系电话",
                      prop: "emergencyContactMobile",
                    },
                  },
                  [
                    _c("v-input", {
                      attrs: {
                        placeholder: "请输入联系人电话",
                        maxlength: 11,
                        width: _vm.width,
                        disabled: _vm.isDisabled,
                      },
                      model: {
                        value: _vm.form.emergencyContactMobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "emergencyContactMobile", $$v)
                        },
                        expression: "form.emergencyContactMobile",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("col2-block", { attrs: { title: "证件信息" } }, [
            _c(
              "div",
              { staticClass: "salary-div" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "上传证件信息", prop: "fileUrlList" } },
                  [
                    _c("v-uploader", {
                      attrs: {
                        action: _vm.uploadURL,
                        fileList: _vm.form.fileUrlList,
                        limit: 10,
                        onlyImage: true,
                        fileSize: 0.2,
                        disabled: _vm.isDisabled,
                        beforeRemove: _vm.beforeRemoveImgs,
                        stopDelete: _vm.isDisabled,
                      },
                      on: {
                        "update:fileList": function ($event) {
                          return _vm.$set(_vm.form, "fileUrlList", $event)
                        },
                        "update:file-list": function ($event) {
                          return _vm.$set(_vm.form, "fileUrlList", $event)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "tip",
                            fn: function () {
                              return [
                                _c("div", [
                                  _vm._v(
                                    "\n              注：1、可上传员工身份证、学位证等图片信息，支持JPG(JPEG)、GIF、PNG、BMP，单张不得超过200K\n            "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "padding-left": "28px" } },
                                  [
                                    _vm._v(
                                      "\n              2、支持10张以内图片上传，可一次上传多张\n            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2633874790
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }