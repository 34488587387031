// 薪资信息组件
<template>
  <div class="container" v-if="show">
    <div class="salary-div">
      <el-form-item
        label="薪资档级"
        prop="salaryGrade"
        :rules="[
          {
            required: true,
            validator: validateSalary,
            trigger: 'blur',
          },
        ]"
      >
        <v-select
          v-model="form.salaryGrade"
          :options="salaryGradeOps"
          :width="width"
          :disabled="!form.tenantId || isDisabled"
          @change="getbasicInfo"
        />
      </el-form-item>
    </div>
    <div class="salary-div">
      <col2-item
        label="基本工资"
        :text="salaryStandardInfo.baseSalary || '--'"
        :span="8"
        required
      />
      <col2-item
        label="绩效工资"
        :text="salaryStandardInfo.performanceSalary || '--'"
        :span="8"
        required
      />
    </div>
    <div class="salary-div">
      <col2-item
        label="社会保险"
        :text="salaryStandardInfo.socialInsurance || '--'"
        :span="8"
        required
      />
      <col2-item
        label="社险补贴"
        :text="salaryStandardInfo.socialInsuranceSubsidy || '--'"
        :span="8"
        required
      />
      <col2-item
        label="公积金"
        :text="salaryStandardInfo.providentFund || '--'"
        :span="8"
        required
      />
    </div>
    <div class="salary-div">
      <col2-item
        label="公积金补贴"
        :text="salaryStandardInfo.providentFundSubsidy || '--'"
        :span="8"
        required
      />
      <col2-item
        label="通讯补贴"
        :text="salaryStandardInfo.communicationSubsidy || '--'"
        :span="8"
        required
      />
      <col2-item
        label="交通补贴"
        :text="salaryStandardInfo.transportationSubsidy || '--'"
        :span="8"
        required
      />
    </div>
    <div class="salary-div">
      <col2-item
        label="餐贴"
        :text="salaryStandardInfo.mealSubsidy || '--'"
        :span="8"
        required
      />
      <col2-item
        label="汽油费补贴"
        :text="salaryStandardInfo.petrolSubsidy || '--'"
        :span="8"
        required
      />
      <col2-item
        label="岗位补贴"
        :text="salaryStandardInfo.jobRankSubsidy || '--'"
        :span="8"
        required
      />
    </div>
    <div class="salary-div">
      <col2-item
        label="其他补贴"
        :text="salaryStandardInfo.otherSubsidy || '--'"
        :span="8"
        required
      />
      <col2-item
        label="考核奖"
        :text="salaryStandardInfo.assessmentAward || '--'"
        :span="8"
        required
      />
      <col2-item
        label="高温费"
        :text="salaryStandardInfo.highTemperatureFee || '--'"
        :span="8"
        required
      />
    </div>
    <div class="salary-div">
      <col2-item
        label="社保基数"
        :text="salaryStandardInfo.socialInsuranceBase || '--'"
        :span="8"
        required
      />
      <col2-item
        label="公积金基数"
        :text="salaryStandardInfo.providentFundBase || '--'"
        :span="8"
        required
      />
      <col2-item
        label="养老基数"
        :text="salaryStandardInfo.pensionBase || '--'"
        :span="8"
        required
      />
    </div>
    <div class="salary-div">
      <col2-item
        label="生育基数"
        :text="salaryStandardInfo.fertilityBase || '--'"
        :span="8"
        required
      />
      <col2-item
        label="失业基数"
        :text="salaryStandardInfo.unemploymentBase || '--'"
        :span="8"
        required
      />
      <col2-item
        label="工伤基数"
        :text="salaryStandardInfo.workInjuryBase || '--'"
        :span="8"
        required
      />
    </div>
    <div class="salary-div">
      <col2-item
        label="医疗基数"
        :text="salaryStandardInfo.medicalBase || '--'"
        :span="8"
        required
      />
    </div>
  </div>
</template>

<script>
import {
  Col2Item
} from "components/bussiness";
import { getSalaryStandardByTenantAndGradeURL } from "../api";
export default {
  name: "salaryInfo",
  components: {
    Col2Item,
  },
  props: {
    // 接收父级传入的表单数据
    _form: {
      type: Object,
      default: {},
    },
    // 组件的显示隐藏
    show: {
      type: Boolean,
      default: true,
    },
    //薪资档级
    salaryGradeOps: {
      type: Array,
      default: []
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: {}, // 组件内部数据
      edit: false,
      width: 182,
      salaryStandardInfo: {}
    };
  },
  computed: {},
  watch: {
    _form: function (newValue) {
      console.log(newValue, "newValue");
      if (newValue) {
        this.form = Object.assign({}, newValue);
        if(this.form.salaryGrade) {
          this.getbasicInfoList();
        }
      }
    },
  },
  created() {},
  mounted() {
  },
  methods: {
    validateSalary(rule, value, callback) {
      const salaryRegular = /\d+|\d.\d+|0\.\d+/;
      if (value === "") {
        callback(new Error("该项不能为空"));
      } else if (!salaryRegular.test(value)) {
        callback(new Error("格式不正确，请输入数字"));
      } else {
        callback();
      }
    },
    getbasicInfo(data, echo) {
      if (data) {
        this.getbasicInfoList();
      } else {
        this.salaryStandardInfo = {};
      }

      if (!echo) {
        console.log(data, echo);
      }
    },
    getbasicInfoList() {
      let params = {
        tenantId: this.form.tenantId,
        salaryGrade: this.form.salaryGrade,
      };
      this.$axios
        .get(getSalaryStandardByTenantAndGradeURL, { params: params })
        .then((res) => {
          if (res.status === 100 && res.data) {
            this.salaryStandardInfo = res.data;
          } else {
            this.form.salaryGrade = undefined;
            this.salaryStandardInfo = {};
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  .talent-div {
    display: flex;
    align-items: center;
  }
  .salary-div {
    display: flex;
    // align-items: center;
  }
  .salary-pad {
    padding-top: 20px;
  }
  .summary-salary {
    text-align: right;
    padding-bottom: 20px;
  }
  .col2-container {
    width: 1262px !important;
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
